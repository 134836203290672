import { defineStore } from 'pinia';
import store from '@/stores';

export interface UserInfo {
    password: string;
    roleMap: {
        'ODX user': number;
        'DSA Admin': number;
    };
    mobile: string;
    active: boolean;
    fullName: string;
    avatar: string;
    id: number;
    account: string;
    email: string;
}

export interface userState {
    userInfo: UserInfo | null;
}

export const useUserStore = defineStore({
    id: 'app-user',
    state: (): userState => {
        return {
            userInfo: null
        };
    },
    getters: {},
    actions: {
        setUserInfo(e: UserInfo | null = null) {
            this.userInfo = e;
        },
        updateState<K extends keyof userState>(key: K, value: userState[K]) {
            // @ts-ignore
            this[key] = value;
        }
    }
});

export function useUserStoreWithOut() {
    return useUserStore(store);
}
