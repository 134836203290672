import router from '@/router';
import type { AxiosInstance, Method } from 'axios';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import { T } from '@/utils';
// import storage from '@/utils/cache';
// import { debounce } from '@/utils';
import { useVehicleStoreWithOut } from '@/stores/modules/vehicle';
import errorObj from './errorCode';
import { CVALUES } from '@/model/constdef';

const vehicleStore = useVehicleStoreWithOut();
const vehicleInfo = computed(() => vehicleStore.vehicleInfo);

interface BaseType {
    getConfigParams(): any;

    interceptors(instance: AxiosInstance, url: string | number | undefined): any;

    request(requestMethod: Method, url: string, data?: any): any;
}

interface AxiosRequestType {
    baseURL?: string;

    url?: string | undefined;

    data?: any;

    params?: any;

    method?: string;

    headers: any;

    timeout?: number;

    value?: any;

    cancelToken?: any;
}

const CancelToken = axios.CancelToken;

const sources: any = [];

const removeSource = (config: any) => {
    for (const item in sources) {
        if (sources[item].umet === config.url + '&' + config.method) {
            sources[item].cancel('cancel the duplicate request');

            sources.splice(item, 1);
        }
    }
};

// const clearRepeatTips = debounce(() => {
//     ElNotification({
//         title: T('ApiTip.error'),
//         message: errorObj['10001'],
//         type: 'error',
//         duration: 2500
//     });
// }, 100);

class AxiosHttpRequest implements BaseType {
    timeout: number;

    constructor() {
        this.timeout = 60000;
    }

    getConfigParams() {
        return {
            timeout: this.timeout,

            headers: {}
        };
    }

    interceptors(instance: AxiosInstance, hidde: any) {
        instance.interceptors.request.use(
            (config: AxiosRequestType) => {
                // cancel duplicate
                if (config?.headers?.requestMultiple != 'y') {
                    removeSource(config);
                    config.cancelToken = new CancelToken((c) => {
                        sources.push({
                            umet: config.url + '&' + config.method,

                            cancel: c
                        });
                    });
                }
                config.headers['Content-Type'] = config.headers['Content-Type'] || CVALUES.CONTENT_TYPE_JSON;
                config.headers['uuid'] = vehicleInfo.value.uuid;
                return config;
            },

            (error: any) => {
                Promise.reject(error);
                return false;
            }
        );

        instance.interceptors.response.use(
            (res: any): any => {
                removeSource(res.config);
                const code = res.status || 200;

                let msg = '';
                switch (code) {
                    case 401:
                        msg = 'authentication error';
                        router.push('/login');

                        break;

                    case 403:
                        msg = 'no right';

                        break;

                    case 404:
                        msg = 'not found';

                        break;

                    case 200:
                        msg = 'OK';

                        break;
                    case 201:
                        msg = 'OK';

                        break;
                    case 204:
                        msg = 'OK';

                        break;

                    default:
                        msg = 'unknow error.';

                        break;
                }
                const status = res.data.result || 200;
                switch (status) {
                    case 10001:
                    case 10002:
                        router.push('/login');
                }

                if (code == 200 && res?.data?.code == 0 && !hidde) {
                    if (res?.data?.result == 10106) {
                        router.push('/login');
                    }
                    ElNotification({
                        title: errorObj[status]?.title || T('ApiTip.error'),
                        message: errorObj[status]?.msg || errorObj[status] + ' [' + status + ']',
                        type: errorObj[status]?.type || 'error',
                        duration: 2500
                    });
                }

                if (code === 200 || code === 201 || code === 204) {
                    return Promise.resolve(res.data);
                } else {
                    return Promise.reject(res.data);
                }
            },

            (error: any) => {
                const { message, response } = error;
                switch (response && response.status) {
                    case 401:
                        // clearRepeatTips();
                        router.push('/login');
                        break;
                    case 500:
                        ElNotification({
                            title: T('ApiTip.error'),
                            message: T('ApiTip.errorConnect'),
                            type: 'error'
                        });
                }
                return Promise.reject(response ? response.data : response);
            }
        );
    }

    request(requestMethod: Method, url: string, data?: any, opt?: any) {
        const instance = axios.create();
        const hiddeGlobalNotification = Boolean(opt?.hiddeGlobalNotification);

        /* eslint-disable */

        const config: {
            method: Method;
            url: string;
            params?: object;
            data?: any;
            headers?: any;
        } = { method: requestMethod, url: url.replace(/^[$\/]/, '') };
        if (opt?.headers) {
            config.headers = opt.headers;
        }
        if ((requestMethod === 'get' && data) || (requestMethod === 'delete' && data)) {
            config.params = data;
        } else if (requestMethod === 'post' || requestMethod === 'put') {
            config.data = data;
        }
        const options = Object.assign(this.getConfigParams(), config);
        this.interceptors(instance, hiddeGlobalNotification);
        return instance(options);
    }
}
const httpUtil = new AxiosHttpRequest();

function get(url: string, params?: any, opt?: any) {
    return httpUtil.request('get', url, params, opt);
}
function post(url: string, data?: any, opt?: any) {
    return httpUtil.request('post', url, data, opt);
}
function put(url: string, data?: any) {
    return httpUtil.request('put', url, data);
}
function del(url: string, params?: any) {
    return httpUtil.request('delete', url, params);
}
export default {
    get,
    post,
    put,
    del
};
