<template>
    <div class="m-table">
        <div v-if="columnsSetting" class="columns-setting">
            <el-popover
                ref="popover"
                v-model="data.showPopover"
                :width="207"
                :offset="20"
                @show="popoverShowEvent"
            >
                <div class="popover-content-top">
                    <el-button @click="cancel">{{ $t('Common.cancel') }}</el-button>
                    <el-button @click="saveColumns">{{ $t('Common.confirm') }}</el-button>
                </div>
                <div class="popover-content-center">
                    <el-checkbox v-model="data.allChecked" @change="selectAllColumns">
                        {{ $t('Common.checkAll') }}
                    </el-checkbox>
                    <el-button class="invert" text @click="invertSelectColumns">
                        <img src="@/assets/images/fanxuan@2x.png" alt="反选" width="13px" />
                        <span>{{ $t('Common.invert') }}</span>
                    </el-button>
                </div>
                <div
                    class="popover-content-footer"
                    :style="{ maxHeight: data.popoverContentMaxHeight + 'px' }"
                >
                    <el-checkbox-group v-model="data.checkedList" class="check-wrapper">
                        <draggable
                            v-model="data.settingColumns"
                            v-bind="data.dragOptions"
                            @start="data.isDragging = true"
                            @end="data.isDragging = false"
                        >
                            <el-checkbox
                                v-for="item in data.settingColumns"
                                :key="item.prop"
                                :label="item.prop"
                            >
                                {{ item.label }}
                            </el-checkbox>
                        </draggable>
                    </el-checkbox-group>
                </div>
            </el-popover>
            <el-button ref="columnsSettingBtn" v-popover:popover text style="padding: 0">
                <img src="@/assets/images/setting@2x.png" alt="" />
            </el-button>
        </div>
        <template v-if="data.showTable">
            <el-table
                ref="mTable"
                v-loading="data.loading"
                :data="data.tableData"
                :row-key="setRowKey"
                :height="height!"
                :border="true"
                v-bind="$attrs"
                @filter-change="filterChange"
                @sort-change="sortChange"
            >
                <template v-for="(item, index) in data.newColumns">
                    <el-table-column
                        v-if="item.type === 'selection'"
                        :key="index - 1"
                        column-key="selection"
                        type="selection"
                        v-bind="item"
                        :resizable="false"
                    />
                    <el-table-column
                        v-else
                        :key="index"
                        v-bind="item"
                        :column-key="item.prop"
                        :sortable="
                            item.prop === 'index'
                                ? false
                                : 'sortable' in item
                                ? item.sortable
                                : 'custom'
                        "
                        :min-width="
                            xScroll
                                ? item.slotName !== 'action'
                                    ? item.minWidth
                                    : item.width || 180
                                : ''
                        "
                    >
                        <template #default="{ row, column, $index }">
                            <slot
                                v-if="item.slotName"
                                :name="item.slotName"
                                v-bind="{ row, column, $index, columnIndex: index }"
                            />
                            <span v-else>{{ row[column.property] }}</span>
                        </template>
                        <template #header="{ row, column }">
                            <span @click="emits('headerClick', index)">{{ column.label }}</span>
                            <slot
                                v-if="item.headerSlotName"
                                :name="item.headerSlotName"
                                v-bind="{ row, column, columnIndex: index }"
                                :origin-column="item"
                            />
                            <template v-else>
                                <el-popover
                                    v-if="!column.filters && item.search"
                                    placement="bottom"
                                    popper-class="popover-search"
                                    width="200"
                                    trigger="click"
                                >
                                    <div class="popover-content" style="padding-bottom: 10px">
                                        <el-input
                                            v-model="data.searchValue[column.property]"
                                            :placeholder="$t('Common.inputContent')"
                                            prefix-icon="el-icon-search"
                                        />
                                    </div>
                                    <div class="popover-search-container el-table-filter__bottom">
                                        <button
                                            :disabled="!data.searchValue[column.property]"
                                            :class="
                                                !data.searchValue[column.property]
                                                    ? 'is-disabled'
                                                    : ''
                                            "
                                            @click="search()"
                                        >
                                            {{ $t('Common.search') }}
                                        </button>
                                        <button @click="resetSearch(column.property)">
                                            {{ $t('Common.reset') }}
                                        </button>
                                    </div>
                                    <template #reference>
                                        <el-icon
                                            class="popover-reference"
                                            style="vertical-align: middle; margin-right: 5px"
                                            @click="popoverStop"
                                        >
                                            <Search />
                                        </el-icon>
                                    </template>
                                </el-popover>
                            </template>
                        </template>
                    </el-table-column>
                </template>
            </el-table>
            <div v-if="showPage" class="page-container">
                <el-pagination
                    :current-page="data.page.currentPage"
                    :page-sizes="data.page.pageSizes"
                    :page-size="data.page.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="data.page.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </template>
    </div>
</template>

<script lang="ts" setup>
import type { MTableData, MTableColumn, Page } from '@/types/mTable';
import { useAppStoreWithOut } from '@/stores/modules/app';
import _ from 'lodash-es';
import WebStorage from '@/utils/cache';
import $ from 'jquery';
import type { ComputedRef } from 'vue';
import { setTreeData } from '@/utils';
import draggable from 'vuedraggable';
import type { CheckboxValueType } from 'element-plus';

export interface Condition {
    page: Page;
    search: Record<string, any>;
    order: string;
}
export type Callback = (res?: { total: number; content: Record<string, any>[] }) => void;
interface Prop {
    data?: any[] | boolean;
    columns: MTableColumn[];
    setDataMethod?: (condition: Condition, callback: Callback) => void;
    isTreeData?: boolean;
    showPage?: boolean;
    columnsKey?: string;
    columnsSetting?: boolean;
    xScroll?: boolean;
}

const props = withDefaults(defineProps<Prop>(), {
    data: false,
    columns: () => [],
    setDataMethod: () => {},
    isTreeData: false,
    showPage: true,
    columnsKey: '',
    columnsSetting: false,
    xScroll: true
});

// variable
const attrs = useAttrs();
const appStore = useAppStoreWithOut();
const route = useRoute();
const mTable = ref();
const columnsSettingBtn = ref();
const data: MTableData = reactive({
    loading: false,
    showTable: true,
    newColumns: [],
    tableData: [],
    page: {
        default: 20,
        pageSize: 20,
        pageSizes: [10, 20, 50, 100, 150],
        total: 0,
        currentPage: 1
    },
    order: '',
    searchValue: {},
    searchFormatValue: {},
    showPopover: false,
    allChecked: false,
    popoverContentMaxHeight: 200,
    settingColumns: [],
    copySettingColumns: [],
    checkedList: [],
    isDragging: false,
    dragOptions: {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
    }
});
// computed
const height: ComputedRef<number | null> = computed(() => {
    let cusHeight: number = attrs.height as number;
    const maxHeight = attrs['max-height'] || attrs.maxHeight;
    if (maxHeight) return null;
    if (!cusHeight) cusHeight = appStore.contentHeight - 58 - 30 - 52;
    return props.showPage ? cusHeight - 44 : cusHeight - 5;
});
// watch
watch(
    () => props.data,
    () => {
        Object.assign(data, { tableData: props.data || [] });
    }
);
watch(
    () => props.columns,
    () => {
        resetPagination();
        getCheckedColumns();
        nextTick(() => {
            mTable.value.doLayout();
        });
    }
);
watch(
    () => data.newColumns,
    () => {
        nextTick(() => {
            mTable.value.doLayout();
        });
    }
);
// methods
const resetPagination = () => {
    Object.assign(data.page, {
        total: 0,
        currentPage: 1,
        pageSize: 20
    });
};
const popoverShowEvent = () => {
    const settingColumns = _.cloneDeep(data.copySettingColumns);
    const checkedList = data.newColumns
        .filter((item) => item.slotName !== 'action' && item.type !== 'selection')
        .map((item) => item.prop);
    Object.assign(data, { settingColumns, checkedList });
};
const getCheckedColumns = () => {
    const columns = _.cloneDeep(props.columns);
    const columnsKey: string = props.columnsKey || (route.name as string);
    if (!props.columnsSetting) {
        Object.assign(data, { newColumns: columns });
        return;
    }
    const userInfo: Record<string, any> = WebStorage.get('userInfo');
    const { fields } = userInfo;
    const currentFields = fields && JSON.parse(fields)[columnsKey];
    const copySettingColumns = _.cloneDeep(columns).filter(
        (item) => item.slotName !== 'action' && item.type !== 'selection'
    );
    if (currentFields) {
        setCheckedColumns(currentFields, columns);
        return;
    }
    Object.assign(data, {
        newColumns: columns,
        checkedList: data.settingColumns.map((item) => item.prop),
        copySettingColumns
    });
    const newFields = (fields && JSON.parse(fields)) || {};
    newFields[columnsKey] = data.settingColumns.map((item, index) => ({
        key: item.prop,
        checked: true,
        index
    }));
    userInfo.fields = JSON.stringify(newFields);
    WebStorage.set('userInfo', userInfo);
};
const setCheckedColumns = (currentFields: MTableColumn[], columns: MTableColumn[]) => {
    const checkedFields = currentFields.filter((item) => item.checked);
    const checkedProps = checkedFields.map((item) => item.prop);
    const columnsProps = currentFields.map((item) => item.prop);
    const newColumns = columns.filter((item) => {
        const checkedIndex = checkedProps.indexOf(item.prop);
        if (checkedProps.includes(item.prop)) {
            item.index = checkedFields[checkedIndex].index;
        } else if (columnsProps.includes(item.prop)) {
            const current = currentFields[columnsProps.indexOf(item.prop)];
            item.index = current.index;
        }
        if (item.type === 'selection') {
            item.index = 0;
        }
        if (item.slotName === 'action') {
            item.index = columns.length;
        }
        return checkedIndex !== -1 || item.slotName === 'action' || item.type === 'selection';
    });
    newColumns.sort((a, b) => (a.index as number) - (b.index as number));
    const settingColumns = columns
        .filter((item) => item.slotName !== 'action' && item.type !== 'selection')
        .sort((a, b) => (a.index as number) - (b.index as number));
    const copySettingColumns = _.clone(settingColumns);
    Object.assign(data, {
        newColumns,
        settingColumns,
        copySettingColumns,
        checkedList: checkedProps
    });
};
const calcPopoverContentHeight = () => {
    if (!columnsSettingBtn?.value?.$el) return false;
    const position = $(columnsSettingBtn.value.$el).offset()!;
    const contentHeight = document.documentElement.offsetHeight;
    Object.assign(data, {
        popoverContentMaxHeight: contentHeight - position.top - 180
    });
};
const popoverStop = () => {
    // @ts-ignore
    window.event.stopPropagation();
};
const setTableData = (reset = false) => {
    Object.assign(data, { loading: true });
    if (reset) resetPagination();
    props.setDataMethod(
        {
            page: data.page,
            search: data.searchValue,
            order: data.order
        },
        async (res) => {
            if (res) {
                let tableData: Record<string, any>[] = [];
                if (props.isTreeData) {
                    tableData = setTreeData(res.content);
                }
                Object.assign(data, { tableData: tableData.length ? tableData : res.content });
                Object.assign(data.page, { total: res.total });
            } else {
                Object.assign(data, { loading: false });
            }
        }
    );
};
const filterChange = (filters: Record<string, any>) => {
    const filtersKeys = Object.keys(filters);
    const searchVal = data.searchValue;
    filtersKeys.forEach((item) => {
        if (filters[item].length) {
            searchVal[item] = filters[item].join(',');
        } else {
            delete searchVal[item];
        }
    });
    Object.assign(data, { searchVal });
    setTableData(true);
};
const sortChange = (sort: any) => {
    if (sort?.column?.sortable !== 'custom' && !data.order) return;
    let order = data.order;
    if (sort.order) {
        order = sort.order === 'ascending' ? `${sort.prop},asc` : `${sort.prop},desc`;
    } else {
        order = '';
    }
    Object.assign(data, { order });
    setTableData();
};
const search = () => {
    setTableData(true);
};
const resetSearch = (key: string) => {
    const { searchValue } = data;
    searchValue[key] = '';
    Object.assign(data, { searchValue });
};
const handleSizeChange = (val: number) => {
    Object.assign(data.page, { pageSize: val });
    setTableData();
};
const handleCurrentChange = (val: number) => {
    Object.assign(data.page, { currentPage: val });
    setTableData();
};
const cancel = () => {
    Object.assign(data, {
        showPopover: false,
        allChecked: false
    });
    setTimeout(resetSettingFields, 500);
};
const saveColumns = async () => {
    let userInfo: Record<string, any> = WebStorage.get('userInfo');
    const { fields } = userInfo;
    const newFields = (fields && JSON.parse(fields)) || {};
    const columnsKey: string = props.columnsKey || (route.name as string);
    newFields[columnsKey] = data.settingColumns.map((item, index) => ({
        prop: item.prop,
        checked: data.checkedList.includes(item.prop),
        index
    }));
    userInfo.fields = JSON.stringify(newFields);
    WebStorage.set('userInfo', userInfo);
    Object.assign(data, {
        showPopover: false,
        allChecked: false
    });
    await getCheckedColumns();
    refreshTable();
};
const selectAllColumns = (val: CheckboxValueType) => {
    Object.assign(data, {
        checkedList: val ? data.settingColumns.map((item) => item.prop) : []
    });
};
const invertSelectColumns = () => {
    Object.assign(data, {
        checkedList: data.settingColumns
            .filter((item) => data.checkedList.includes(item.prop))
            .map((item) => item.prop)
    });
};
const resetSettingFields = () => {
    const { fields } = WebStorage.get('userInfo');
    const columnsKey: string = props.columnsKey || (route.name as string);
    const currentFields = fields && JSON.parse(fields)[columnsKey];
    if (currentFields) {
        Object.assign(data, {
            checkedList: data.settingColumns
                .filter((item) => currentFields.indexOf(item.prop) !== -1)
                .map((item) => item.prop)
        });
    }
};
const refreshTable = () => {
    Object.assign(data, { showTable: false });
    nextTick(() => {
        Object.assign(data, { showTable: true });
    });
};
const setRowKey = (row: Record<string, any>): string => {
    return row.id;
};
// Lifecycle
onMounted(() => {
    getCheckedColumns();
    nextTick(() => {
        if (props.data) {
            Object.assign(data, { tableData: props.data });
        } else {
            setTableData();
        }
        calcPopoverContentHeight();
    });
});
// Emit
const emits = defineEmits<{
    (e: 'headerClick', index: number): void;
}>();
// expose
defineExpose({
    mTable,
    setTableData
});
</script>

<style lang="scss">
@import './Mtable.scss';
</style>
<style scoped lang="scss">
@import './Mtable-scoped.scss';
</style>
