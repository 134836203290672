<template>
    <el-dialog
        :model-value="modelValue"
        :class="allPropsData.width === '400px' ? 'middle-dialog dsa-modal-wrap' : 'dsa-modal-wrap'"
        :fullscreen="fullscreen"
        :close-on-click-modal="false"
        :show-close="false"
        :destroy-on-close="true"
        @close="close()"
    >
        <template v-if="$slots.title" #header>
            <slot name="title" />
        </template>
        <template v-else #header>
            <div class="head-all">
                <div class="title">{{ $attrs.title }}</div>
                <div class="head-ri">
                    <icon v-show="showFullscreen" class="active" :icon="fullscreen ? 'fullscreen-shrink' : 'fullscreen-expand'" :size="24" @click="showFull"></icon>
                    <el-icon class="active-icon" style="font-size: 30px; margin-left: 20px" @click="close">
                        <Close />
                    </el-icon>
                </div>
            </div>
        </template>
        <div v-if="showBody" class="body" :style="{ maxHeight: height + 'px' }">
            <slot />
        </div>
        <template v-if="showFooter && slots.footer" #footer>
            <slot name="footer" />
        </template>
        <template v-else-if="showFooter" #footer>
            <div :class="allPropsData.width === '400px' ? 'dsa-dialog-footer center-content' : 'dsa-dialog-footer'">
                <el-button class="cancel" @click="close()">{{ cancelButtonText }}</el-button>
                <el-button class="confirm" type="primary" :loading="loading" @click="onOk()">
                    {{ $t('Common.confirm') }}
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script lang="ts" setup>
import { useAppStoreWithOut } from '@/stores/modules/app';
import { T } from '@/utils';

interface Props {
    modelValue: boolean;
    showFooter?: boolean;
    showFullscreen?: boolean;
    cancelButtonText?: string;
    customCancel?: boolean;
    formBuild?: any;
}

// props
const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
    showFooter: true,
    showFullscreen: true,
    cancelButtonText: T('Common.cancel'),
    customCancel: false,
    formBuild: null
});

// variable
const slots = useSlots();
const attrs = useAttrs();
const app = useAppStoreWithOut();
const loading = ref(false);
const showBody = ref(false);
const fullscreen = ref(false);
// computed
const allPropsData = computed(() => {
    return Object.assign({
        width: '800px',
        top: '100px',
        closeOnClickModal: false,
        ...attrs,
        customClass: `${attrs.className} dsa-modal-wrap`
    });
});
const height = computed(() => {
    return fullscreen.value ? (props.showFooter ? app.contentHeight - 60 - 60 - 50 : app.contentHeight - 60 - 50) : app.contentHeight - 54 - 100 - 50 - 60 - 70;
});
// watch
watch(
    () => props.modelValue,
    (val) => {
        if (!val) {
            setTimeout(() => {
                showBody.value = false;
            }, 500);
        } else {
            showBody.value = true;
        }
    }
);
// emits
const emits = defineEmits<{
    (e: 'update:modelValue', value: boolean): void;
    (e: 'fullscreen', value: boolean): void;
    (e: 'confirm', callback: (reset?: boolean) => void): void;
}>();
// methods
const onOk = () => {
    loading.value = true;
    emits('confirm', (reset = false) => {
        loading.value = false;
        if (reset && props.formBuild) {
            props.formBuild.formRef.clearValidate();
        }
    });
};
const close = () => {
    if (props.formBuild) {
        props.formBuild.formRef.clearValidate();
    }
    emits('update:modelValue', false);
};
const showFull = () => {
    fullscreen.value = !fullscreen.value;
    emits('fullscreen', fullscreen.value);
};
defineExpose({
    height,
    fullscreen
});
</script>

<style lang="scss">
@import './Mdialog.scss';
</style>
