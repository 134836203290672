import router, { routes } from '@/router/index';
import type { RouteLocationNormalized } from 'vue-router';
import { useUserStoreWithOut } from '@/stores/modules/user';
const userStore = useUserStoreWithOut();
const userInfo = computed(() => userStore.userInfo);
// console.log('routes', routes);
const hiddeMenuPath = ['/login', '/ODX-generation-detail'];

function findLevelByPath(routes: any, targetPath: string) {
    let foundLevel = null;

    function traverse(route: any) {
        if (route.path === targetPath) {
            foundLevel = route.meta && route.meta.level;
        } else if (route.children && route.children.length > 0) {
            route.children.forEach(traverse);
        }
    }

    routes.forEach(traverse);

    return foundLevel;
}
// 辅助函数，用于检查两个数组是否有交集
function intersects(arr1: any, arr2: any) {
    return arr1.some((value: any) => arr2.includes(value));
}
router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: any) => {
    // 没有权限时，跳转到首页
    if (userInfo.value && !hiddeMenuPath.includes(to.path)) {
        const role = Object.values(userInfo.value.roleMap);
        const level: any = findLevelByPath(routes[0].children!, to.path);
        if (!(Array.isArray(level) ? intersects(role, level) : role.includes(level))) {
            next({ path: '/' });
        }
    }
    next();
});
