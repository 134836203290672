import MTable from './MTable/MTable.vue';
import MDialog from './MDialog/MDialog.vue';
import MIcon from './Icon/icon.vue';
import type { App, Component } from 'vue';

const mComponents: { [key: string]: Component } = {
    MTable,
    MDialog,
    MIcon
};

const install = (app: App<Element>) => {
    Object.keys(mComponents).forEach((key) => {
        app.component(key, mComponents[key]);
    });
};

const MComponents = {
    install,
    ...MTable,
    ...MDialog,
    ...MIcon
};

export default MComponents;
