import { createApp } from 'vue';
// import VueNativeSock from 'vue-native-websocket';
import App from './App.vue';
import i18n from './locales/i18n';
import router from './router';
import stores from './stores';
import MComponents from '@/components/common';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import './permission';
import './assets/style/index.scss';
import './theme/index.scss';
import 'element-plus/theme-chalk/src/message.scss';
import './assets/iconfont/iconfont.css';
// import hljs from 'highlight.js';
// import 'highlight.js/styles/atom-one-light.css';
import 'highlight.js/styles/atom-one-dark.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'
const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
app.use(hljsVuePlugin)
app.use(MComponents);
app.use(stores);
app.use(i18n);
app.use(router);
// app.use(VueNativeSock, 'ws://localhost:9000/ws', { connectManually: true, reconnectionAttempts: Infinity, reconnectionDelay: 3000 });
app.mount('#app');
