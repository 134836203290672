import request from '@/utils/request';

const api = '/ddp/company/';

export default {
    getCompanyList() {
        return request.get(`${api}getCompanyList`);
    },
    createCompany(data: any) {
        return request.post(`${api}createCompany`, data);
    },
    deleteCompany(id: any) {
        return request.post(`${api}deleteCompany?id=${id}`);
    },
    getCompanyVehicleList() {
        return request.get(`${api}getCompanyVehicleList`);
    }
};
