// @ts-ignore
import messages from '@intlify/vite-plugin-vue-i18n/messages';
import { useLocaleStoreWithOut } from '@/stores/modules/locale';
import { createI18n } from 'vue-i18n';
const ls = useLocaleStoreWithOut();
const locale = ls.getLocale();
const i18n = createI18n({
    globalInjection: true,
    locale,
    legacy: false,
    fallbackLocale: ['zh-CN'],
    availableLocales: ['zh-CN', 'en'],
    sync: true,
    silentTranslationWarn: true, // true - warning off
    missingWarn: false,
    silentFallbackWarn: true,
    messages
});

export default i18n;
