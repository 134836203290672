import { T } from '@/utils';

const errorObj: any = {
    '10001': T('ApiTip.10001'),
    '10002': T('ApiTip.10002'),
    '10003': T('ApiTip.10003'),
    '10004': T('ApiTip.10004'),
    '10005': T('ApiTip.10005'),
    '10009': T('ApiTip.10009'),
    '10100': T('ApiTip.10100'),
    '10101': T('ApiTip.10101'),
    '10102': T('ApiTip.10102'),
    '10103': T('ApiTip.10103'),
    '10104': T('ApiTip.10104'),
    '10105': T('ApiTip.10105'),
    '10106': T('ApiTip.10106'),
    '10108': T('ApiTip.10108'),
    '10200': T('ApiTip.10200'),
    '10201': T('ApiTip.10201'),
    '10202': T('ApiTip.10202'),
    '10203': T('ApiTip.10203'),
    '10300': T('ApiTip.10300'),
    '10301': T('ApiTip.10301'),
    '10302': T('ApiTip.10302'),
    '10304': T('ApiTip.10304'),
    '10305': T('ApiTip.10305'),
    '10306': T('ApiTip.10306'),
    '10307': T('ApiTip.10307'),
    '10308': T('ApiTip.10308'),
    '10400': T('ApiTip.10400'),
    '10401': T('ApiTip.10401'),
    '10402': T('ApiTip.10402'),
    '10403': T('ApiTip.10403'),
    '10404': T('ApiTip.10404'),
    '10406': T('ApiTip.10406'),
    '10407': T('ApiTip.10407'),
    '10500': T('ApiTip.10500'),
    '10501': T('ApiTip.10501'),
    '10502': T('ApiTip.10502'),
    '10503': T('ApiTip.10503'),
    '10504': T('ApiTip.10504'),
    '10506': T('ApiTip.10506'),
    '10507': T('ApiTip.10507'),
    '10508': {
        title: T('ApiTip.warning'),
        type: 'warning',
        msg: T('ApiTip.10508')
    },
    '10509': T('ApiTip.10509'),
    '10600': T('ApiTip.10600'),
    '10601': T('ApiTip.10601'),
    '10602': T('ApiTip.10602'),
    '10603': T('ApiTip.10603'),
    '10604': T('ApiTip.10604'),
    '10700': T('ApiTip.10700'),
    '10800': T('ApiTip.10800'),
    '10801': T('ApiTip.10801'),
    '10802': {
        title: T('ApiTip.warning'),
        type: 'warning',
        msg: T('ApiTip.10802')
    },
    '10803': T('ApiTip.10803'),
    '10900': T('ApiTip.10900'),
    '11000': T('ApiTip.11000'),
    '11001': T('ApiTip.11001'),
    '10701': T('ApiTip.10701'),
    '10901': T('ApiTip.10901'),
    '10902': T('ApiTip.10902'),
    '10903': T('ApiTip.10903')
};

export default errorObj;
