import { getStorageShortName } from '@/utils/env';
import { createStorage as create } from './storageCache';
import type { CreateStorageParams } from './storageCache';
import { enableStorageEncryption } from '@/settings/encryptionSetting';
import { DEFAULT_CACHE_TIME } from '@/settings/encryptionSetting';

export type Options = Partial<CreateStorageParams>;

const createOptions = (storage: Storage, options: Options = {}): Options => {
    return {
        // No encryption in debug mode
        hasEncrypt: enableStorageEncryption,
        storage,
        prefixKey: getStorageShortName(),
        ...options
    };
};

export const WebStorage = create(createOptions(sessionStorage));

export const createStorage = (storage: Storage = sessionStorage, options: Options = {}) => {
    return create(createOptions(storage, options));
};

export const createSessionStorage = (options: Options = {}) => {
    return createStorage(sessionStorage, { ...options, timeout: DEFAULT_CACHE_TIME });
};

export const createLocalStorage = (options: Options = {}) => {
    return createStorage(localStorage, { ...options, timeout: DEFAULT_CACHE_TIME });
};

export const localS = createLocalStorage();

export default WebStorage;
