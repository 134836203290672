import type { LocaleSetting, LocaleType } from '@/types/config';

export const LOCALE: { [key: string]: LocaleType } = {
    ZH_CN: 'zh-CN',
    EN_US: 'en'
};

export const localeSetting: LocaleSetting = {
    // Locale
    locale: LOCALE.EN_US,
    // Default locale
    fallback: LOCALE.EN_US,
    // available Locales
    availableLocales: [LOCALE.ZH_CN, LOCALE.EN_US]
};

// locale list
export const localeList: { text: string; value: string }[] = [
    {
        text: '简体中文',
        value: LOCALE.ZH_CN
    },
    {
        text: 'English',
        value: LOCALE.EN_US
    }
];
