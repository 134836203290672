import { defineStore } from 'pinia';
import store from '@/stores';

export interface userState {
    vehicleInfo: Record<string, string>;
    vehicleList: any[];
}

export const useVehicleStore = defineStore({
    id: 'app-vehicle',
    state: (): userState => {
        return {
            vehicleInfo: {},
            vehicleList: []
        };
    },
    getters: {},
    actions: {
        updateState<K extends keyof userState>(key: K, value: userState[K]) {
            // @ts-ignore
            this[key] = value;
        }
    }
});

export function useVehicleStoreWithOut() {
    return useVehicleStore(store);
}
