import i18n from '@/locales/i18n';
import type { FormList } from '@/types/formBuilder';
import type { MTableColumn } from '@/types/mTable';
import type { Path } from 'vue-i18n';
import { useUserStore } from '@/stores/modules/user';
import router from '@/router';
// import userAPi from '@/api/user';

const { t } = i18n.global;
export const setTreeData = (data: Record<string, any>[], id = -1): Record<string, any>[] => {
    const top = data.filter((item) => item.parentId === id);
    return top.map((item) => {
        const children = setTreeData(data, item.id);
        return {
            ...item,
            children: children.length ? children : null
        };
    });
};

export const T = (key: Path | number, options?: any): string => {
    return t(key, options);
};

export const transformColumnsToForm = (columns: MTableColumn[]) => {
    let columnsFormList: FormList[] = [];
    columns.forEach((item) => {
        if (item.form) {
            const formObj: FormList & { index: number } = {
                index: item.form.index || 0,
                label: item.form.label || item.label,
                key: item.form.key || item.prop,
                tagName: item.form.tagName,
                required: 'required' in item.form ? item.form.required : true,
                props: item.form.props || {},
                colSpan: item.form.colSpan || 12
            };
            if ('defaultValue' in item.form) {
                formObj.defaultValue = item.form.defaultValue;
            }
            if ('rules' in item.form) {
                formObj.rules = item.form.rules;
            }
            if ('on' in item.form) {
                formObj.on = item.form.on;
            }
            if ('hidden' in item.form) {
                formObj.hidden = item.form.hidden;
            }
            if (item.form.tagName === 'el-select') {
                formObj.children = [
                    {
                        tagName: 'el-option',
                        props: item.form.optionsProps || {
                            label: 'name',
                            value: 'id'
                        },
                        options: item.form.options || [],
                        innerValue: item.form.innerValue!
                    }
                ];
            }
            if (item.form.tagName === 'el-radio-group') {
                formObj.children = [
                    {
                        tagName: 'el-radio',
                        innerValue: item.form.innerValue! || 'name',
                        options: item.form.options || [],
                        props: item.form.optionsProps || {
                            label: 'id'
                        }
                    }
                ];
            }
            if ('slots' in item.form) {
                formObj.slots = item.form.slots;
            }
            if ('customSlots' in item.form) {
                formObj.children = [{ customSlots: item.form.customSlots }];
            }
            columnsFormList.push(formObj);
        }
    });
    columnsFormList = columnsFormList.sort((a: any, b: any) => {
        if (a.index < b.index) return -1;
        return 1;
    });
    return columnsFormList;
};

export const resetReactive = (aimObj: Record<string, any>, assignObj: Record<string, any>) => {
    Object.keys(aimObj).map((key) => {
        delete aimObj[key];
    });
    Object.assign(aimObj, assignObj);
};

export const resetArr = (aimArr: any[], assiArr?: any[]) => {
    aimArr.splice(0, aimArr.length);
    assiArr &&
        assiArr.forEach((item: any) => {
            aimArr.push(item);
        });
    return aimArr;
};
export function getUuid(): string {
    const s: any[] = [];
    const hexDigits = '0123456789abcdef';
    for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-';

    const uuid = s.join('');
    return uuid;
}

// 获取图片转base64
export const getBase64 = (file: any) => {
    return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        let imgResult: any = '';
        reader.readAsDataURL(file);
        reader.onload = function () {
            imgResult = reader.result;
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.onloadend = function () {
            resolve(imgResult);
        };
    });
};
// 验证版本号
export const isValidVersion = (version: any) => {
    // 版本号必须由三部分组成，每部分为数字，可以以 'v' 或 'V' 开头
    const versionRegex = /^(v|V)?\d+\.\d+\.\d+$/;

    return versionRegex.test(version);
};
export const debounce = <F extends (...args: any[]) => void>(func: F, delay: number): ((...args: Parameters<F>) => void) => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    return (...args: Parameters<F>) => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            func(...args);
        }, delay);
    };
};
// 转地址栏参数
const toQueryString = (obj) => {
    let queryString = '';
    if (obj instanceof FormData) {
        // 如果是 FormData 对象
        for (const [key, value] of obj.entries()) {
            queryString += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
        }
    } else {
        // 如果是普通对象
        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                queryString += encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]) + '&';
            }
        }
    }
    // 移除最后的 "&" 字符
    return queryString.slice(0, -1);
};
// 下载文件
export const downLoad = async (url: string, data: any) => {
    await getUser();
    ElNotification({
        title: 'Notice',
        message: T('Tip.downloading'),
        type: 'info',
        duration: 2000
    });
    const queryParams = toQueryString(data);
    const link = document.createElement('a');
    link.href = queryParams ? `${url}?${queryParams}` : url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};

// 判断用户的权限
export const hasPermission = (role: number[]) => {
    const store = useUserStore();
    const roleMap = store.userInfo?.roleMap;
    const level = Object.values(roleMap!);
    // 如果是DSA的用户,那么就是有所有的权限
    if (level.includes(1)) {
        return true;
    }
    if (role.length === 0) {
        return false;
    } else {
        return level.some((item) => role.includes(item));
    }
};
// 获取用户权限的等级
export const getPermissionLevel = () => {
    const store = useUserStore();
    const roleMap = store.userInfo?.roleMap;
    const level = Object.values(roleMap!);
    return Math.min(...level);
};

// export const letterCasePermutation = (arr: string[] = []) => {
//     let res: string[] = [];
//     const fn: any = (str: string) => {
//         if (str.length === 0) return [''];
//         const a = str[0],
//             b = [a.toLowerCase(), a.toUpperCase()];
//         b[0] === b[1] && b.pop();
//         if (str.length === 1) return b;
//         return fn(str.slice(1)).reduce((r: string[], c: string) => [...r, ...b.map((m) => m + c)], []);
//     };
//     arr.forEach((element: string) => {
//         res = res.concat(fn(element));
//     });
//     return res;
// };

export const letterCasePermutation = (arr: string[] = []) => {
    const res: string[] = [];
    arr.forEach((element: string) => {
        res.push(element.toLowerCase());
        res.push(element.toUpperCase());
    });
    return res;
};
// 获取用户的信息,判断session是否过期
export const getUser = async () => {
    const Api = await import('@/api/user');
    return Api.default.user();
};
