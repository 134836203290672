import type { AppRouteRecordRaw } from './types';
import { createRouter, createWebHashHistory } from 'vue-router';
import type { RouteRecordRaw } from 'vue-router';
import { T } from '@/utils';
const templateEmpty = () => import('@/pages/templateEmpty/templateEmpty.vue');
// 菜单图片
import Dashboard from '@/assets/DDPIcon/Dashboard.svg';
import ODX from '@/assets/DDPIcon/ODX2.svg';
import ECU from '@/assets/DDPIcon/ECU.svg';
import Generation from '@/assets/DDPIcon/Generation.svg';
import BV from '@/assets/DDPIcon/BV.svg';
import EV from '@/assets/DDPIcon/EV.svg';
import FG from '@/assets/DDPIcon/FG.svg';
import System from '@/assets/DDPIcon/System.svg';
export const routes: AppRouteRecordRaw[] = [
    {
        path: '/layout',
        name: 'layout',
        component: () => import('@/components/layout/index.vue'),
        meta: {
            level: 'user'
        },
        children: [
            {
                path: '/',
                name: T('Title.dashboard'),
                component: () => import('@/pages/Dashboard/index.vue'),
                meta: {
                    level: [2, 3, 4, 1, 5],
                    img: Dashboard
                }
            },
            {
                path: '/ODX',
                name: 'ODX',
                component: () => templateEmpty(),
                meta: {
                    level: [2, 4, 1, 3, 5],
                    img: ODX
                },
                children: [
                    {
                        path: '/framework-config',
                        name: T('Title.frameworkConfig'),
                        component: () => import('@/pages/projectInfo/frameworkConfig.vue'),
                        meta: {
                            level: [1]
                        }
                    },
                    {
                        path: '/project-info',
                        name: T('Title.projectInfo'),
                        component: () => import('@/pages/projectInfo/index.vue'),
                        meta: {
                            level: [2, 1, 4, 3, 5]
                        }
                    },
                    {
                        path: '/PDX-history',
                        name: T('Title.pdxHistory'),
                        component: () => import('@/pages/projectInfo/pdxHistory.vue'),
                        meta: {
                            level: [2, 1, 4, 3, 5]
                        }
                    },
                    {
                        path: '/simulation',
                        name: T('Title.simulation'),
                        component: () => import('@/pages/projectInfo/simulation.vue'),
                        meta: {
                            level: [2, 1, 4, 3, 5]
                        }
                    },
                    {
                        path: '/jar-file',
                        name: T('Title.jarFile'),
                        component: () => import('@/pages/projectInfo/JarFile.vue'),
                        meta: {
                            level: [9999]
                        }
                    }
                ]
            },
            {
                path: '/ECU',
                name: 'ECU',
                component: () => templateEmpty(),
                meta: {
                    level: [2, 1, 4, 3, 5],
                    disabled: true,
                    img: ECU
                },
                children: [
                    {
                        path: '/BV',
                        name: T('Title.bv'),
                        component: () => import('@/pages/ECU/BV/index.vue'),
                        meta: {
                            level: [2, 1, 4, 3, 5],
                            img: BV
                        }
                    },
                    {
                        path: '/FG',
                        name: T('Title.fg'),
                        component: () => import('@/pages/ECU/FG/index.vue'),
                        meta: {
                            level: [2, 1, 4, 3, 5],
                            img: FG
                        }
                    },
                    {
                        path: '/EV',
                        name: T('Title.ev'),
                        component: () => import('@/pages/ECU/EV/index.vue'),
                        meta: {
                            level: [2, 1, 4, 3, 5],
                            img: EV
                        }
                    }
                ]
            },
            {
                path: '/ODX-generation',
                name: T('Title.pdxGeneration'),
                component: () => import('@/pages/ODXGeneration/index.vue'),
                meta: {
                    level: [2, 1, 4],
                    img: Generation
                }
            },
            {
                path: '/system',
                name: T('Title.system'),
                component: () => templateEmpty(),
                meta: {
                    level: [2, 1, 4, 3, 5],
                    img: System
                },
                children: [
                    {
                        path: '/user-manage',
                        name: T('Title.users'),
                        component: () => import('@/pages/System/user_manage/index.vue'),
                        meta: {
                            level: [2, 1]
                        }
                    },
                    {
                        path: '/log',
                        name: T('Title.log'),
                        component: () => import('@/pages/System/log/index.vue'),
                        meta: {
                            level: [2, 1, 3, 4, 5]
                        }
                    },
                    {
                        path: '/question',
                        name: T('Title.question'),
                        component: () => import('@/pages/System/question/index.vue'),
                        meta: {
                            // level: [2, 1, 4, 3]
                            level: [999]
                        }
                    },
                    // {
                    //     path: '/company',
                    //     name: T('Page.System.company'),
                    //     component: () => import('@/pages/System/company/index.vue'),
                    //     meta: {
                    //         level: [1]
                    //     }
                    // },
                    {
                        path: '/tools',
                        name: T('Title.tools'),
                        component: () => import('@/pages/tools/index.vue'),
                        meta: {
                            level: [2, 1, 3, 4, 5]
                        }
                    },
                    {
                        path: '/documents',
                        name: T('Title.documents'),
                        component: () => import('@/pages/documents/index.vue'),
                        meta: {
                            level: [2, 1, 3, 4, 5]
                        }
                    },
                    {
                        path: '/setting',
                        name: T('Title.setting'),
                        component: () => import('@/pages/System/setting/index.vue'),
                        meta: {
                            level: [2, 1]
                        }
                    }
                ]
            },
            {
                path: '/dsa-admin',
                name: T('Title.dsaAdmin'),
                component: () => templateEmpty(),
                meta: {
                    level: [1],
                    img: System
                },
                children: [
                    {
                        path: '/company',
                        name: T('Page.System.company'),
                        component: () => import('@/pages/DsaAdmin/company/index.vue'),
                        meta: {
                            level: [1]
                        }
                    },
                    {
                        path: '/ticket',
                        name: T('Title.ticket'),
                        component: () => import('@/pages/DsaAdmin/ticket/index.vue'),
                        meta: {
                            // level: [1]
                            level: [999]
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/login/login.vue'),
        meta: {},
        children: []
    },
    {
        path: '/hiddemneu',
        name: 'hiddemneu',
        component: () => import('@/components/layout/index.vue'),
        meta: {
            level: [2, 3, 1]
        },
        children: [
            {
                path: '/ODX-generation-detail',
                name: 'Generation Detail',
                component: () => import('@/pages/ODXGeneration/detail.vue'),
                meta: {
                    level: [2, 3, 1],
                    img: Generation
                }
            }
        ]
    }
];
const router = createRouter({
    history: createWebHashHistory(),
    routes: routes as unknown as RouteRecordRaw[],
    strict: true,
    scrollBehavior: () => ({ left: 0, top: 0 })
});

export default router;
