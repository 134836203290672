// @ts-ignore
import pkg from '../../package.json';

/**
 * @description: Get environment variables
 * @returns:
 * @example:
 */
export function getEnv(): string {
    return import.meta.env.MODE;
}

export const getConfigFileName = (env: Record<string, any>) => {
    return `__PRODUCTION__${env.VITE_GLOB_APP_SHORT_NAME || '__APP'}__CONF__`
        .toUpperCase()
        .replace(/\s/g, '');
};

export function getAppEnvConfig() {
    return {
        VITE_GLOB_APP_TITLE: 'DSA',
        VITE_GLOB_APP_SHORT_NAME: 'monitorweb'
    };
}

export function getCommonStoragePrefix() {
    const { VITE_GLOB_APP_SHORT_NAME } = getAppEnvConfig();
    return `${VITE_GLOB_APP_SHORT_NAME}__${getEnv()}`.toUpperCase();
}

export function getStorageShortName() {
    return `${getCommonStoragePrefix()}${`__${pkg.version}`}__`.toUpperCase();
}

/**
 * @description: Is it a development mode
 * @returns:
 * @example:
 */
export function isDevMode(): boolean {
    return import.meta.env.DEV;
}

/**
 * @description: Is it a production mode
 * @returns:
 * @example:
 */
export function isProdMode(): boolean {
    return import.meta.env.PROD;
}
