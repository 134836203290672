import { LOCALE_KEY } from '@/enums/cacheEnum';
import { localeSetting } from '@/settings/localeSetting';
import store from '@/stores';
import { createLocalStorage } from '@/utils/cache';
import { defineStore } from 'pinia';
import type { LocaleSetting, LocaleType } from '@/types/config';

const ls = createLocalStorage();
const lsLocaleSetting = (ls.get(LOCALE_KEY) || localeSetting) as LocaleSetting;

interface LocaleState {
    localInfo: LocaleSetting;
}

export const useLocaleStore = defineStore({
    id: 'app-locale',
    state: (): LocaleState => ({
        localInfo: lsLocaleSetting
    }),
    getters: {},
    actions: {
        setLocaleInfo(info: Partial<LocaleSetting>) {
            this.localInfo = { ...this.localInfo, ...info };
            ls.set(LOCALE_KEY, this.localInfo);
        },
        getLocale() {
            return this.localInfo.locale;
        }
    }
});

// Need to be used outside the setup
export function useLocaleStoreWithOut() {
    return useLocaleStore(store);
}
