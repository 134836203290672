<template>
    <el-config-provider :locale="currentLocal === 'en' ? en : zhCn">
        <router-view></router-view>
    </el-config-provider>
</template>

<script lang="ts" setup>
import { ElConfigProvider } from 'element-plus';
import en from 'element-plus/lib/locale/lang/en';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { useLocaleStoreWithOut } from './stores/modules/locale';
const store = useLocaleStoreWithOut();
const currentLocal = computed(() => {
    return store.getLocale();
});
// provide('reload', reload);
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2e75bf;
    font-size: 14px;
}
* {
    margin: 0;
    padding: 0;
}
#app,
body {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
</style>
