import store from '@/stores';
import { defineStore } from 'pinia';
import defaultLogo from '@/assets/images/dsa_logo.png';
import companyApi from '@/api/company';
interface AppState {
    contentWidth: number;
    contentHeight: number;
    collapse: boolean;
    setting: {
        protocol: string[];
        flash: boolean;
        coding: boolean;
    };
    currentVehicle: Record<string, any>;
    logo: string;
    companyList: Record<string, any>[];
}

export const useAppStore = defineStore({
    id: 'app-app',
    state: (): AppState => ({
        contentWidth: document.documentElement.clientWidth,
        contentHeight: document.documentElement.clientHeight,
        collapse: false,
        setting: {
            protocol: ['CAN', 'CANFD'],
            flash: true,
            coding: true
        },
        currentVehicle: {},
        logo: defaultLogo,
        companyList: []
    }),
    getters: {},
    actions: {
        updateCollapse(collapse: boolean) {
            this.collapse = collapse;
        },
        updateContent() {
            this.contentHeight = document.documentElement.clientHeight;
            this.contentWidth = document.documentElement.clientWidth;
        },
        updateState<K extends keyof AppState>(key: K, value: AppState[K]) {
            // @ts-ignore
            this[key] = value;
        },
        getCompanyList() {
            return companyApi.getCompanyList().then((res) => {
                this.companyList = res.data;
            });
        }
    }
});

// Need to be used outside the setup
export function useAppStoreWithOut() {
    return useAppStore(store);
}
